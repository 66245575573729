#canvas-2 {
    transform: rotate(180deg);
}

#canvas-3 {
    transform: scaleY(-1);
}

.mandala-canvas {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid;
    height: 500px;
    width: 500px;
    justify-content: center;
}

.mandala-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}