/* LCMGenerator.css */
.lcm-generator-container {
    font-family: Arial, sans-serif;
    max-width: 450px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    text-align: center;
  }
  
  h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  input[type="number"] {
    width: 90%;
    padding: 10px;
    margin-top: 12px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .result {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  /* LCMGenerator.css */
  /* ... (previous CSS rules) */
  
  .small-input {
    width: 50px; /* Adjust the width as needed */
    padding: 5px;
  }
  
  