/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.heading {
    font-family: 'Pacifico', cursive;
    font-size: 35px;
}

/* Define an animation with keyframes */
@keyframes colorMove {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 0%;
    }
}

/* Apply the animation to your .container */
.container {
    height: auto;
    width: 800px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: linear-gradient(120deg, #92ed96 0%, #65a381 100%);
    background-size: 200% 100%;
    animation: colorMove 4s linear infinite alternate; /* Use 'alternate' to make it reverse direction instead of resetting */
}


input {
    padding-left: 5px;
    font-size: 20px;
    height: 36px;
}

.middle,
.right {
    width: 120px;
}

.switch {
    padding: 5px;
    background-color: rgb(226, 252, 184);
    border-radius: 50%;
    cursor: pointer;
}

.result {
    box-sizing: border-box;
    align-items: center;
}

.convertButton {

    font-weight: bold;
    font-size: 20px;
    margin-top: 15px;
    border: 2px solid forestgreen;
    background-image: linear-gradient(120deg, #65a381 0%, #92ed96 100%);
    cursor: pointer;
    transition: background 0.3s ease, border-color 0.3s ease, color 0.3s ease; /* Add transitions for smooth color changes */
}

.convertButton:hover {
    background-image: linear-gradient(120deg, #92ed96 0%, #65a381 100%);
    border-color: #65a381;
    color: white;
}

p,
h3,
button,
.switch {
    color: forestgreen;
}

p {
    font-size: 30px;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    background-color: white;
}