/* Add this CSS to your project's stylesheet */
.bmi-container {
   
    background-color:white; 
    border-radius: 10px; 
    padding: 15px;
    text-align: center; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) ;
    max-width: 450px;
    margin:0 auto;
    margin-top: 50px;
  }
  
  .bmi-result {
    margin-top: 20px;
  }
.bmi-scale {
    width: 80%;
    margin: 20px auto;
    position: relative;
  }
  
  .scale {
    display: flex;
    justify-content: space-between;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
  }
  
  .pointer {
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: red;
    left: 0; 
    top: 0;
    transition: left 0.5s;
  }
  
  .scale div {
    flex-grow: 1;
    text-align: center;
    padding: 5px;
    font-weight: bold;
  }
  
button {
    flex: 1 1;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 11px 5px;
    font-size: 12px;
}
.cred-container{
    margin-bottom: 10px;
}

  